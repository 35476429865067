<template>
    <div class="ranking">
        <van-sticky>
            <div class="ranking-title">
                <div class="coach">
                    教练排名
                </div>
                <div class="btn">
                    <van-button type="primary" :class="{'active': showMonthBtn}" @click="changeMonth" round size="mini">本月</van-button>
                    <van-button type="primary" :class="{'active': showQuarterBtn}" @click="changeQuarter" round size="mini">本季</van-button>
                    <van-button type="primary" :class="{'active': showYearBtn}" @click="changeYear" round size="mini">本年</van-button>
                </div>
                <!-- <div class="time" >
                    <span class="date" @click="showDate=true">{{date}}</span>
                    <span class="triangle"></span>
                </div> -->
            </div>
        </van-sticky>
        <div class="table">
            <div class="table_head">
                <span class="ranking">排名</span>
                <span class="name">姓名</span>
                <span class="times">鞍时 (单位：时)</span>
            </div>
            <!-- 人员 -->
            <div class="table_body">
                <div class="item" v-for="(item, i) in rankList" :key="i">
                    <span class="ranking">{{i+1}}</span>
                    <span class="name ellipsis">{{item.name}}</span>
                    <span class="times">
                        <span class="num">{{item.value | money}}</span>
                        <span class="bar" :style="{ backgroundColor: activeColor[i%8], width: (maxWidht/rankMax)*item.value*100 + '%' }"></span>
                    </span>
                </div>
            </div>
            <no-more v-show="showNoMore"/>
        </div>
        <!-- 暂无数据 -->
        <no-data v-show="showData"/>
    </div>
</template>

<script>
export default {
    data() {
        return {
            api: 'coach/base/coach/coach-ranking',
            rankList: [],
            date: '',
            rankMax: 90,
            showMonthBtn: true,
            showYearBtn: false,
            showQuarterBtn: false,
            showDate: false,
            num: 1,
            max: 40,
            maxWidht: 0.8,
            activeColor: ['#37a2da', '#32c5e9', '#67e0e3', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#FB6260'],

            showNoMore: false,
            showData: false,
            type: 1
        }
    },
    filters:{
        money(val) {
            let str = val
            if(val>10000) {
                str = val/10000 + 'w'
            }
            if(val>1000){
                str = val/1000 + 'k' 
            }
            return str
        }
    },
    methods: {
        //月
        changeMonth() {
            this.showMonthBtn = true
            this.showYearBtn = false
            this.showQuarterBtn = false
            this.type = 1
            this.init()
        },
        //年
        changeYear() {
            this.showMonthBtn = false
            this.showYearBtn = true
            this.showQuarterBtn = false
            this.type = 3
            this.init()
        },
        //季
        changeQuarter() {
            this.showMonthBtn = false
            this.showYearBtn = false
            this.showQuarterBtn = true
            this.type = 2
            this.init()
        },
        init() {
            this.showData = false
            this.showNoMore = false
            this.rankList = []
            this.getList()
        },
        getList() { 
            this.$help.loading()
            this.$api.post('coach/base/coach/coach-ranking', {type: this.type}).then(res=>{
                //console.log(res)
                delete res.rootData
                if(JSON.stringify(res) !== "{}"){
                    for(let i in res){
                        this.rankList.push({
                            name: i,
                            value: Object.values(res[i])[0]
                        })
                    }
                    this.rankList.sort((a, b) => b.value-a.value)
                    this.rankMax = this.rankList[0].value;
                }
                if(this.rankList.length === 0){
                    this.showData = true
                    this.showNoMore = false
                }else{
                    this.showData = false
                    this.showNoMore = true
                }
            })
            .finally(this.$help.loaded)
        },
    },
    mounted() {
        this.init()
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .ranking{
        &-title{
            display: flex;
            padding: 10px 20px;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            .coach{
                font-size: 14px;
                font-weight: bold;
            }
            .van-button{
                background-color: #fff;
                color: $gray;
                border-color: $gray;
                padding: 5px 10px;
            }
            .active{
                color: $work;
                border-color: $work;
            }
            .time {
                display:flex;
                align-items: center;
                position: relative;
                padding-left: 50px;
                .date{
                    background: $boxShadow;
                    color: $work;
                    padding: 5px 20px 5px 10px;
                    border-radius: 28px;
                    //opacity: .5;
                }
                .triangle{
                    border:5px solid transparent;
                    border-top:5px solid $work;
                    position: absolute;
                    top:45%;
                    right: 5px;
                }
            }
        }
        .table{
            padding: 0 20px 0;
            // background-color: #fff;
            .table_head{
                display: flex;
                justify-content: space-between;
                font-weight: bold;
                line-height: 40px;
                .ranking {
                    flex: 2;
                    text-align: center;
                }
                .name {
                    flex: 4;
                    text-align: center;
                    margin-right: 20px;
                }
                .times { 
                    flex: 5;
                    max-width: 150px;
                    text-align: left;
                    //padding-left: 15px;
                }
            }
            .table_body{
                .item{
                    display: flex;
                    justify-content: space-around;
                    line-height: 40px;
                    border-top: 1px solid #ededed;
                    .ranking {
                        flex: 2;
                        text-align: center;
                    }
                    .name {
                        flex: 4;
                        text-align: center;
                        margin-right: 20px;
                    }
                    .times { 
                        max-width: 300px;
                        flex: 5;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        .bar{
                            display: inline-block;
                            height: 10px;
                            border-radius: 28px;
                            margin-left: 5px;
                        }
                        .num{
                            display: inline-block;
                            min-width: 25px;
                            text-align: center;
                        }
                    }
                }
                
            }
            .table-no-data{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top:10px;
                img{
                    width: 180px;
                    height: 100px;
                    opacity: .6;
                }
                .text{
                    text-align: center;
                    color:#969697
                }
            }
        }
    }

</style>